.MuiTabs-root.thick-indicator .MuiTabs-indicator {
  height: 4px;
}

.no-wrap {
  overflow-wrap: normal;
}

.large-icon-btn .MuiButton-startIcon svg {
  font-size: 2em;
}

.MuiGrid-root.one-line {
  height: 1.43em;
}

.progress-container {
  height: 4px;
}

.one-line-p {
  height: 1.5em;
}

.btn-with-circular-progress.MuiButton-root.MuiButton-sizeMedium {
  .MuiButton-startIcon.MuiButton-iconSizeMedium {
    max-width: 20px;
    width: 20px;

    .MuiCircularProgress-root {
      max-width: 14px;
      max-height: 14px;
      margin-left: 4px;
      margin-right: 4px;
    }

    .MuiSvgIcon-fontSizeMedium {
      font-size: 20px;
    }
  }
}

.clickable {
  cursor: pointer;
}

svg {
  .hover-overlay {
    fill: none;
    stroke: none;
    pointer-events: all;
    //stroke-width: 2px;
  }

  .pointer-events {
    pointer-events: all;
  }
}
