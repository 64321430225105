svg.bubble-map {
  width: 100%;
  height: auto;
  max-height: 435px;
}

.bubble-map-container {
  display: flex;
  width: 100%;
  border: 1px solid hsl(0, 0%, 86%);
  box-shadow: 0px 8px 16px hsla(0, 0%, 78%, 0.25);
  box-sizing: content-box;
  background-color: lightgray;

  & > .tooltip {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid black;
    padding: 1em;
    border-radius: 3px;
  }

  &:hover > .tooltip {
    display: block;
  }
}