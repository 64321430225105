@import "src/style/variables";
@import "src/style/colors";

.mark-opportunity-button {
  .marked {
    color: #707070;
    margin: 0 .1em;
  }

  .marked.active {
    color: $colorGreen;
  }
}
