.financial-filters-component {
  .analysis-year-input {
    width: 9em;
  }
  .probability-input {
  }
  .savings-input {
    width: 9em;
  }
  .scope-input {
    width: 9em;
  }
  .doi-input {
    width: 9em;
  }
  .lever-input {
    width: 11em;
  }
  .approved-input {
    width: 9em;
  }

  .cluster-input {
    width: 25em;
  }
  .company-input {
    width: 25em;
  }

  .category-input {
    width: 45em;
  }
}