.collapsible-indent-tree-viz {
  //user-select: none;
  cursor: default;

  path {
    fill: none;
    stroke: gray;
    stroke-width: 1;
  }

  .icons path {
    fill: none;
    stroke: black;
  }
}
