.single-bar-chart {
  position: relative;
  //background-color: seagreen;
  line-height: 0;

  &.hover-labels svg {
    .bar-wrapper .value-label {
      fill: none;
    }

    .bar-wrapper:hover .value-label {
      fill: currentColor;
    }
  }

  &.hack-hide-old svg {
    .bar-wrapper.old .value-label {
      display: none;
    }
  }
}
