.parent-suppliers-toolbar {
  margin-bottom: 1rem;
  //margin-left: 4.3rem;

  .save-progress-wrapper {
    min-width: 5.2em;
    text-align: center;

    &.with-button {
      .MuiButtonBase-root {
        -moz-border-radius-bottomleft: 0;
        border-bottom-left-radius: 0;
        -moz-border-radius-bottomright: 0;
        border-bottom-right-radius: 0;

        height: 32px;
      }

      .MuiLinearProgress-root {
        -moz-border-radius-bottomleft: 4px;
        border-bottom-left-radius: 4px;

        -moz-border-radius-bottomright: 4px;
        border-bottom-right-radius: 4px;
      }

      &.inactive-loader {
        .MuiLinearProgress-bar, .MuiLinearProgress-root {
          background-color: transparent;
        }
      }
    }
  }
}
