.taxonomy-suggestor-page {

  scrollbar-width: thin;

  .taxonomy-suggestor-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //margin: 2em 2em 1em 2em;
    margin: 2.5em 0 0 0;
  }

  .taxonomy-suggestor-filters {
    //margin-top: 3em;
    //margin-bottom: 2em;
  }

  .taxonomy-suggestor-main-container {
    //margin-top: 4em;
    margin-top: 1em;
    margin-bottom: 4em;
  }

  $hardcodedHeight: 55em;

  .taxonomy-container {
    //overflow-y: scroll;
    max-height: $hardcodedHeight;
  }

  .taxonomy-suggestion-selection-container {
    margin-left: 4em;
    max-height: $hardcodedHeight;

    .selection-overview {
      .selection-title {
        color: #193150;
      }

      .selection-overview-table {
        margin-top: 2em;
      }
    }

    .selection-data-section {
      margin-top: 2em;

      .selection-data-toolbar {
        margin-bottom: 1em;
      }
    }
  }

}
