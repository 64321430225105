.synergy-suppliers-page {
  .supplier-detail {
    margin-top: -1em;
    min-height: 55em;
  }

  .title {
    margin-bottom: 1.5em;
  }
}
