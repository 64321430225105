.golden-record-page {
  .golden-record-bars-component {
    //background-color: purple;
    margin-top: 3em;

    .single-bar-chart {
      margin-top: 1em;
      margin-bottom: 1em;

      .left-overflow-area {
        padding-right: 1em;
      }

      .bar-wrapper .bar {
        fill: #193150;
      }
    }
  }

  .golden-record-filter-component {
    //background-color: red;
    margin-top: 3em;
  }

  .golden-record-table-component {
    //background-color: green;
    margin-top: 1em;


    .MuiTableCell-root {
      line-height: 1;
      padding: .5em 0;

      &.cell-sub-table {
        padding: 0;
      }
    }

    .sub-table {
      .MuiTableRow-root, .progress-row {
        background-color: #F6F6F6;
      }

      .MuiTableBody-root {
        .MuiTableCell-root {
          padding: 0 0;
        }

        .MuiTableRow-root:last-of-type {
          &, .MuiTableCell-root {
            border-bottom: none;
          }
        }
      }
    }
  }
}