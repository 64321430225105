@import '../../style/variables';
@import '../../style/colors';

.business-case-generator-page {
  padding: 2em 1em 6em;
  min-height: 40em;

  .bcr-table-wrapper {
    .MuiTableCell-head {
      color: #091344;
      border-bottom-color: #091344;
      border-bottom-width: 1px;
    }

    .MuiTableCell-root {
      padding: 0.5em 1em;
    }
  }

  .business-case-generator-stepper {
    min-height: 4rem; // To align with the header of the report page
  }

  .success-factors-section {
    width: 100%;
    margin-top: 2em;
    min-height: 10em;
    //background-color: #F6F6F6;
    //background-color: #D3D3D3FF;
    padding: 2em;

    textarea {
      line-height: 1.8em;
      font-size: 1.05rem;
    }
  }

  .success-factors-card {
    width: 100%;
    padding: 1em 2em;

    .MuiFormControl-root {
      margin: 1.5em 0;
    }
  }

  .generate-section {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .review-opportunities-component {
    width: 100%;
  }
}
