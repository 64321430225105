@import 'src/style/variables';

.koi-facade,
.dashboard-main-page {
  padding: $pageMargin;
  min-height: 40em;

  .header {
    margin-top: $pageHeaderMargin;
  }

  .page-item {
    margin-top: $pageItemMargin;
  }
}
