@import "src/style/colors";

.taxonomy-editor-wrapper {
  max-height: 700px; // TODO: link to C.profile.taxonomyBuilderHeight
  overflow: auto;
  //&.view-mode-full {
  //  max-height: 75vh;
  //  overflow: auto;
  //}
  &.view-mode-fit {
    overflow: visible;
  }
}

//.mithra-footer {
//  height: 500px;
//  background-color: azure;
//}

svg.taxonomy-editor-viz {

  rect.overlay {
    fill: none;
  }

  &.blocked {
    rect.overlay {
      fill: rgba(255, 255, 255, .33);
    }
  }

  rect.bg {
    opacity: 0;
  }

  circle {
    fill: none;
  }

  text {
    user-select: none;
  }

  circle.outer {
    stroke: $colorPartsTableHead;
  }

  .node-group {
    &:hover {
      .label-group {
        opacity: 1 !important;
      }

      text {
        opacity: 1 !important;
        fill-opacity: 1 !important;
      }

      rect.bar {
        fill: rgba(25, 48, 79, 0.90);
        stroke: rgba(25, 48, 79, 0.90);
        stroke-width: 2px;
      }

      circle.outer {
        stroke-width: 2px;
      }
    }

    &.selected {
      circle.inner {
        fill: $colorPartsTableHead;
      }

      rect.bar {
        //fill: rgba(25, 48, 79, 0.75);
      }
    }
  }

  .is-navigate {
    cursor: pointer
  }

  //.is-overlap-both rect.bar {
  //  fill: green;
  //}
  //.is-overlap-top rect.bar {
  //  fill: blue;
  //}
  //.is-overlap-bottom rect.bar {
  //  fill: brown;
  //}

  > .edit-mode {
    .node-group:hover circle {
      opacity: 1;
    }

    .node-group.is-focus {
      rect.bar {
        fill: rgba(25, 48, 79, 0.75);
        stroke: black;
        stroke-width: 4;
      }

      .chevron {
      }
    }
  }

  &.debug {
    border: solid;

    rect {
      stroke: rgba(0, 0, 0, .25);
      stroke-width: 1;

      &.bg {
        fill: #fafafa;
        opacity: 1;
      }
    }

    .is-navigate {
      background-color: yellow;
    }

    .is-focus {
      rect.bar {
        fill: rgba(25, 48, 79, 0.50);
        stroke: black;
        stroke-width: 4;
      }

      &:hover {
        rect.bar {
          stroke: black;
          stroke-width: 4;
        }
      }
    }
  }
}

.taxonomy-editor-breadcrumbs {
  height: 1.5em;
}
