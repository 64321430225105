.fake-loader {

  margin-top: 3em;
  margin-bottom: 3em;

  .MuiCard-root {
    margin: 0 auto;
    width: 50%;
    max-width: 600px;

    background-color: #091344;
    color: #fff;

    .MuiLinearProgress-determinate {
      background-color: rgb(82, 93, 107);
    }

    .MuiLinearProgress-bar1Buffer, .MuiLinearProgress-bar1Determinate {
      background-color: white;
    }

    .MuiLinearProgress-bar2Buffer {
      background-color: rgb(106 119 137);
    }
  }

  .alert-skeleton {
    height: 75px;
    margin-top: -15px;
  }

  .table-skeleton {
    margin-top: 85px;
    height: 1350px;
  }
}

.fake-loader-card.MuiCard-root {
  padding: 1em;

  cursor: default;
  user-select: none;

  .MuiLinearProgress-root {
    margin-top: 1em;
    margin-bottom: 1em;
    height: 8px;
  }

  .MuiLinearProgress-dashed {
    height: 100%;
    margin-top: 1.7px;
  }
}