.taxonomy-suggestion-page {
  .taxonomy-suggestion-demo-table {
    margin-top: 2em;

    .MuiTableCell-root {
      padding: 0.2em;
    }

    .col-controls {
      text-align: center;
    }
  }
}