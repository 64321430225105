.parent-suppliers-step {

  .parent-supplier-review-table {

    .MuiTableCell-root {
      line-height: 1.2;
      padding: 0 .8em;

      &.col-dropdown {
        padding: 0;
      }

      &.cell-sub-table {
        padding: 0;
      }

      &.xs-padding {
        padding: 0 .15em;
      }
    }

    .main-row {
      height: 1rem;

      &.Mui-selected {
        background-color: white;
        box-shadow: 0px 11px 15px -7px #00000033;
        box-shadow: 0px 24px 38px 3px #00000024;
        box-shadow: 0px 9px 46px 8px #0000001F;

        .parent-supplier-select {
          width: 100%;
          padding: 8px;
          gap: 8px;

          Select {
            background-color: #0C3352;
          }
        }
      }
    }

    .col-choose-parent {
      .MuiAutocomplete-root {
        flex: 1;
        margin-right: 1rem;
        margin-top: .7rem;
        margin-bottom: .7rem;
      }

      .accept-reject-buttons {
        margin-top: 1.2rem;
        margin-right: 1rem;
      }
    }

    .sub-table {
      margin-top: .5rem;
      margin-bottom: 2rem; // Added an extra big margin to show difference between main and sub rows

      .sub-row {
        //height: 4.6em;
        &.master {
          background-color: rgba(25, 49, 79, 1/30);
        }

        &.base {
          background-color: rgba(25, 49, 79, 0.1);
        }

        &.removed {
          background-color: rgba(0, 0, 0, 1/20);
        }
      }

      .MuiTableBody-root .MuiTableCell-root {
        //line-height: 1.43;
        line-height: 1.2;
        //padding: 0.5em 0.2em;
        //padding: 0 .8em;
      }
    }
  }
}
