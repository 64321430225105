.koi-facade {
  .header {
    h1 {
      font-size: 2.5rem;
    }
  }

  &.centered-header {
    .header h1 {
      text-align: center;
    }
  }
}
