@import 'src/style/variables';

.dataset-updated-landing-page {
  text-align: center;

  h1, p, button {
    margin-top: 4em;
  }

  h1 {
    font-size: 2rem;
  }

  min-height: 80vh;
}
