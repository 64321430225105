// TODO: There are components that are exactly the same between the Report and Generator pages.
//  We should extract them into a shared component, then this file is no longer needed

.business-case-report-page, .business-case-generator-page {
  .table-header-kpis {
    margin: 1em 0;
    background-color: #F6F6F6;
    padding: 1.5em;

    > .MuiGrid-root {
      padding: 1em;
    }

    > .MuiGrid-root:not(:last-child) {
      border-right: solid 2px #D1D6DD;
    }

    .row-title .MuiTypography-root {
      font-size: 1rem;
      font-weight: 400;
      color: #707070;
    }

    .row-subtitle .MuiTypography-root {
      font-size: 1.25rem;
      font-weight: 700;
      color: #091344;
    }
  }

  .risk-assessment-component {
    width: 100%;
  }

  .success-factors-component {
    margin-top: 2em;
  }

  .report-section {
    margin-top: 7.5em;
  }

  .table-section {

    .table-section-header {
      margin: 1em 0;

      .table-section-header-icon {
        margin-right: 1.25em;
      }

      .table-section-header-title {
        flex-grow: 1; // fill in the rest of the space
        .MuiTypography-root {
          color: #091344;
          font-size: 1.25rem;
          font-weight: 700;
        }
      }
    }
  }

  .general-infos {
    width: 100%;
    margin: 2em 0;

    .MuiAlert-standardInfo {
      background-color: #d5ebf3;
    }
  }

  .ai-opportunity-values-wrapper {
    > .MuiGrid-root {
      margin: 2em 0 2em 0;
    }
  }

  .opp-savings-field-wrapper {
    padding: 0 1em;
    // TODO: Is the order of this componet correct, shouldn't it be below the table?

    .MuiFormLabel-root {
      font-size: 1.4rem;
    }

    .MuiInputBase-root {
      height: 2.5rem;
      font-size: 1.5rem;
    }

    .MuiFormHelperText-root {
      font-size: 0.9rem;
    }
  }
}
