@import "src/style/variables";

.sup-norm-page {
  .supplier-normalization-stepper {
    margin-top: 3em;
  }

  .step {
    min-height: 30em;

    .page-item-actions {
      margin-top: 5em;
    }
  }

  .model-running-step, .applying-results-step {

    .MuiAlert-root {
      margin-top: 8rem;

      font-size: 1.3rem;

      .MuiAlert-icon {
        font-size: inherit;
        margin-top: .25em;
      }

      .MuiCircularProgress-root {
        //margin-left: .8em;
        //margin-right: .3em;
        margin-left: .3em;
        margin-right: .8em;
      }
    }
  }
}

div.help-tooltip {
  max-width: 21em;
  padding: 1em;
}

.key-value-container {
  .key-value-with-help {
    margin-left: 40px;
  }

  > div {
    // TODO: CAT-712
    // TODO: The following line interferes with Franke categorization screen
    margin-bottom: 3em;
  }
}
