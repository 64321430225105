@import "src/style/colors";

.approval-page {
  .approval-subtitle {
    margin-top: 2em;
    display: flex;

    .approval-tabs {
      flex: 1;
    }
  }

  .mithra-table {
    margin-top: 0.5em;
    table-layout: fixed;

    .width-10 {
      width: 10%;
    }

    .width-15 {
      width: 15%;
    }

    .width-20 {
      width: 20%;
    }
  }

  .approval-notes {
    max-height: 8em;
    overflow-y: scroll;
  }

  .approval-accept-reject-btn {
    margin: 1em 0.4em;
    color: white;
    font-weight: normal;
    padding: .5em 1em;

    &.accept {
      background-color: $colorGreen;
    }

    &.reject {
      background-color: $colorRed;
    }
  }

  .approval-info-header {
    background-color: #F6F6F6;
    margin-top: 1em;
    padding: 1em;
    color: gray;

    .approval-avatar {
      color: lightgray;
      font-size: 15em;
    }

    h2 {
      color: darkgray;
    }

    .approval-info-type-date {
      color: gray;
      font-size: 2em;

      svg {
        margin: .5em;
      }

      div {
        display: inline-block;
        font-size: small;
      }
    }
  }

  .detail-button {
    &.MuiButton-text {
      padding-left: 1.2em;
      padding-right: 1.2em;
    }
  }
}

.MuiChip-root {
  &.pending {
    color: white;
    background-color: $colorYellow;
  }

  &.approved {
    color: white;
    background-color: $colorGreen;
  }

  &.rejected {
    color: white;
    background-color: $colorRed;
  }
}
