@import "src/style/variables";
@import "src/style/colors";

.koi-ppv-page {

  .page-item {
    min-height: 25em;
  }

  .MuiTypography-root:not(.MuiAlertTitle-root) {
    color: $colorDashboard;
    //max-height: 1.5em;
  }

  .key-values {
    font-size: 4em;
    margin: 0.5em 0 0.5em 0;
  }

  .key-value-caption {
    width: 80%;
    margin-top: 3em;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.2rem;
  }

  @media (min-width: 1400px) {
    .bar-chart-container {
      width: 75%;
      //margin: 0 auto;
    }
    .bar-chart-title {
      margin-left: 34%;
    }
  }

  .ppv-table-section {
    //margin-top: 10em;

    .ppv-table-subrow-is-baseline {
      .col-combined_unit_cost {
        font-weight: bold;
      }
    }
  }

  .parts-table {
    table {
      min-height: 59em;
    }

    .col-p__description {
      width: 4.5em;
    }

    margin-bottom: $pageItemMargin;
  }


  .single-bar-chart {
    margin-top: 2em;
    margin-bottom: 2em;

    .left-overflow-area {
      //padding-right: 1em;
    }

    .bar-wrapper.old .bar {
      fill: #19315033;
    }

    .bar-wrapper.new .bar {
      fill: #193150;
    }


    &.uncat {
      .MuiTypography-root {
        color: $colorRed;
      }

      .bar-wrapper.old .bar {
        fill: #FF575733;
      }

      .bar-wrapper.new .bar {
        fill: $colorRed;
      }
    }

    .sub-bar {
      fill: $colorRed;
    }
  }

  .key-value-container {
    .value-warning {
      color: $colorRed;
    }

    &.narrow-margin {
      // TODO: Is thie top marging needed?
      margin-top: 1em;

      margin-bottom: 0;

      > div {
        // TODO: The following line negates the effects of the SupplierNormalization scss
        margin-bottom: 0;
      }
    }

    &.wide-margin {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }

  .ppv-search-component {
    margin-left: 1em;
  }


  .ppv-delete-group-modal {
    /* Ensure that text color is red */
    color: red;

    /* Example for making background red */
    background-color: red;

    /* If you need to target specific MUI components within the modal */
    .MuiButton-root {
      background-color: red;
      color: white;
    }

    .MuiTypography-root {
      color: red;
    }

    .MuiSvgIcon-root {
      fill: red;
    }
  }

}
