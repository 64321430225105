@import '../../../style/variables';
@import '../../../style/colors';

.contract-insights-page {

  padding: $pageMargin;
  min-height: 40em;

  .contract-insights-component-page {
    margin-top: $pageHeaderMargin;
  }

  .MuiTypography-root:not(.MuiAlertTitle-root) {
    color: $colorDashboard;
  }

  .key-values {
    font-size: 4em;
    margin: 0.5em 0 0.5em 0;
  }

  .key-value-container {
    .value-warning {
      color: $colorRed;
    }

    &.narrow-margin {
      // TODO: Is thie top marging needed?
      margin-top: 1em;

      margin-bottom: 0;

      > div {
        // TODO: The following line negates the effects of the SupplierNormalization scss
        margin-bottom: 0;
      }
    }

    &.wide-margin {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }

  .MuiAlert-standardInfo {
    background-color: rgba(2, 98, 242, 0.1);
  }

  .contract-row {
    .button {
      color: $colorDashboard;
    }
  }

  .contract-search-component {
    margin-bottom: 1em;
  }

  .row-content {
    .block {
      margin: 1em 0;
      padding: 1em;
      background: #F6F6F6;
      border-radius: 5px;
      border: 1px groove #D1D6DD;
    }
  }

  .contract-insights-table {
    margin-top: 1em;
  }
}
