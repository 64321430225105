@import "src/style/colors";

#root,
.main-component.MuiPaper-root,
.main-component .MuiAppBar-colorPrimary {
  background-color: white;
}

#main-header {
  border-bottom: solid 1px $colorMainBlue;
  color: $colorMainBlue;

  > .MuiToolbar-root {
    min-height: 80px;
  }

  .logo-wrapper {
    // Override the button appearance
    &:not(:hover) {
      background-color: inherit;
    }

    border: inherit;
    color: inherit;
    font-size: inherit;
    min-width: inherit;
    text-transform: inherit;

    // Alignment of menu Logo
    padding: .65em .8em;

    margin-left: .5em;
    margin-right: .5em;
    @media (min-width: 650px) {
      margin-left: 2em;
      margin-right: 2em;
    }
    @media (min-width: 1200px) {
      margin-left: 2em;
      margin-right: 6em;
    }
    @media (min-width: 1400px) {
      margin-left: 2em;
      margin-right: 12em;
    }

    &.MuiButton-root {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    user-select: none;
  }

  .logo-subtitle {
    font-size: 1em;
    line-height: 1;
    margin-top: .25em;
    letter-spacing: -0.02em;
    word-spacing: 0.25em;
    margin-right: 0.02em;

    .logo-subtitle-word1 {
      //margin-left: .35em;
    }

    .logo-subtitle-word2 {
      margin-left: .35em;
    }

    .logo-subtitle-word3 {
      //letter-spacing: 0.065em;
      margin-left: .35em;
    }
  }

  .mithra-logo {
    //width: 180px;
    //height: 40px;
    width: 135px;
    height: 30px;
    line-height: 40px;
    font-size: 40px;
  }

  .MuiFormLabel-root,
  .MuiButton-root {
  }

  .main-menu-button {
    // Alignment of menu
    padding: .35em .55em;
    margin: 0 .5em;

    @media (min-width: 650px) {
      margin: 0 1em;
    }

    &.active {
      text-decoration: underline;
      //border-bottom: 1px solid;
    }
  }

  .button-text {
    text-transform: none;
    font-weight: 200;
  }

  @media (max-width: 1400px) {
    .menu-item-hide-narrow {
      display: none;
    }
  }
}

#main-footer {
}

@import "MainPage";
