@import '../../../../style/variables';
@import '../../../../style/colors';

$dropDownWidth: 88px; // room for 5 digits

.categorization-review-table {
  .MuiTable-root {
    //table-layout: fixed;
  }

  min-height: 30em;

  .MuiCheckbox-root {
    border: 1px solid transparent;
  }

  .is-last-selected {
    .MuiCheckbox-root {
      border-color: opacify($colorDashboard, .5);
    }
  }

  .MuiTableCell-root {
    line-height: 1;
    padding-left: 0;
    padding-right: 0;

    &.col-selection {
      max-width: 50px;
      width: 50px;
    }

    &.col-dropdown {
      max-width: $dropDownWidth;
      width: $dropDownWidth;
      text-align: left;
    }

    &.col-s_name {
      //background-color: red;
    }

    &.col-p_name, &.col-p_description {
      // These have a major impact on the width of the table
      word-break: break-word;
    }

    &.col-s_spend {
      //background-color: hotpink;
      width: 100px;
      font-weight: bold;
    }

    &.col-l1s {
      //background-color: green;
      width: 230px;
    }

    &.col-bus {
      //background-color: green;
      width: 115px;
    }

    &.col-p_ddate_1 {
      //background-color: green;
    }

    &.col-s_cats {
      //background-color: blue;
      width: auto; // The remaining width
    }

    &.col-approval {
      //background-color: yellow;
      width: 90px;
      //min-width: 90px;
      padding-left: 0;
      padding-right: 1em;
      white-space: nowrap;
      overflow: hidden;
    }

    &.col-review-status {
      //background-color: yellow;
      width: 100px;
      //min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
    }

    &.col-review-buttons {
      //background-color: rebeccapurple;
      width: 100px;
      //min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
    }

    &.cell-sub-table.MuiTableCell-root {
      padding: 0 0 0 80px;
      border: none;

      &.has-data {
        border: inherit;
      }
    }

    &.col-p_alg_score {
      //background-color: blue;
      width: 2.2em;
    }
  }

  .supplier-row {
    //background-color: orange;
    height: 4.6em;

    &.loading {
    }

  }

  .supplier-row.is-selected {
    background-color: $colorGrayLight;
  }

  .is-selected {
    background-color: $colorGrayLight;
  }

  &.x-design-narrow .supplier-row {
    height: auto;
  }

  .sub-table {
    margin-top: .5em;
    margin-bottom: 3em;

    .MuiTableBody-root .MuiTableCell-root {
      line-height: 1;
      padding: 0 0.2em;
    }

    .MuiTableCell-root {

      &.col-selection {
        width: 50px;
        max-width: 50px;
      }

      &.col-p_context_1,
      &.col-p_context_2,
      &.col-p_context_3 {
        //background-color: green;
      }

      &.col-p_previous_l {
        //background-color: blue;
        //max-width: 550px;
      }

      &.col-p_suggested_l {
        //background-color: purple;
        //max-width: 550px;
      }

      &.col-p_context_3 {
      }

      &.col-review-status {
        //background-color: red;
        width: 100px;
        min-width: 100px;
        white-space: nowrap;
      }

      &.col-review-buttons {
        //background-color: orange;
        width: 100px;
        min-width: 100px;
        white-space: nowrap;
      }
    }

    .MuiTableBody-root {
      .col-p_description,
      .col-smaller-text {
        font-size: .7rem;
        //background-color: lightgreen;
      }
    }
  }

  .selection-notification-container {
    margin-top: 0.5em;
    height: 4em;

    // A tiny version of the MuiAlert
    .MuiAlert-root.tiny {
      padding: 0 .5em;

      .MuiAlert-icon, .MuiAlert-message, .MuiAlert-action {
        margin-left: .5em;
        margin-right: .5em;
      }

      .MuiAlert-message {
        line-height: 22px;
      }

      .MuiAlert-action {
        padding: 4px 0;

        .MuiButton-root {
          padding-left: 10px;
          padding-right: 10px;

          &.MuiButton-outlined {
            // Because of the border this needs to be 1px narrower
            padding-left: 9px;
            padding-right: 9px;
          }
        }
      }
    }
  }
}

#review-filter-footer {
  position: fixed;
  bottom: 0;
  padding: 1em;
}
