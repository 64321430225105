.my-modal-custom-class {
  .flexible-modal-drag-area-left {
    width: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: move;
  }

  .flexible-modal-drag-area-right {
    width: 50px;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: move;
  }
}