@import "src/style/colors";

.taxonomy-editor-approval-component {

  section {
    margin: 4em 0;
  }

  p {
    margin-bottom: 2em;
  }
}
