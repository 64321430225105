.taxonomy-approval-component {
  section.intro {
    .MuiFormControl-root {
      margin-top: 2em;
    }
  }

  .taxonomy-approval-review-button-bar {
    margin-top: 4em;
    margin-bottom: 1em;
  }
}