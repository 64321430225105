.ppv-opportunity-detail-page {
  .root-stepper {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .MuiTypography-caption {
    color: gray;
    line-height: 1em;
  }

  .MuiTypography-root.value {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .ppv-opp-overview-header {
    .MuiTypography-caption {
      margin-left: 1rem;
    }

    .MuiTypography-root.value {
      margin-left: calc(1rem - 1px);
    }

    margin-bottom: 2em;
  }

  .ppv-opp-overview-cards {
    .MuiCardContent-root {
      padding: 16px;
    }
    margin-bottom: 2em;
  }

  .opp-card {
  }

  .opp-graph-card {
    .opp-graph-header {
      padding: 16px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    }
    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    .line-chart-container {
    }

    .MuiCardContent-root {
      min-height: 22em; // TODO
    }
  }
}