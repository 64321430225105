.ai-busy-component {
  section {
    margin-top: 4em;
  }

  .header {
  }

  .action-btn-container {
    margin-bottom: 4em;
  }
}