.accept-button {
  margin: 16px;
}

.koi-kdf-page {
  .page-item {
    //margin-top: 5em;
    margin-bottom: 2.5em;
  }

  .page-item.sankey-item {
    .hierarchical-sankey-chart {
      margin-top: 1em;
    }
  }

  .page-item.sankey-item {
    margin-top: 10em;
    margin-bottom: 10em;
  }

  .bar-chart-item {
    .key-value-container {
      margin-top: 5em;
    }
  }

  .taxonomy-tree-item {
    .taxonomy-tree-header {
      h2 {
        margin-bottom: 1em;
        text-align: center;
      }

      .buttons {
        text-align: center;
      }

      margin-bottom: 5em;
    }
  }
}
