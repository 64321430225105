@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/utilities";

@import "src/components/main/main";
@import "src/auth/auth";

.main-component .main-component-container {
  & > .MuiGrid-root:not(.advanced) {
    min-height: 80vh;
  }
  & > .advanced {
    min-height: 0;
  }
}

//.main-component .loading-spinner {
//  margin-top: 27em;
//  min-height: 27em;
//  text-align: center;
//}

.main-component .loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensure the spinner takes the full height of the viewport */
}

.fill-content {
  height: 19em; // depr
}

.pre-viz {
  margin-bottom: 2em;

  .MuiTabs-root {
    margin-bottom: 2em;
  }
}

.pre-viz-settings {
  width: 60em;
  margin: 0 auto;
}

a.button {
  text-decoration: none;
}

a.file-button {
  text-decoration: none;

  button {
    text-transform: none;
    text-decoration: underline;
  }
}

button.bigger-button {
  font-size: 1.5em;
  padding: .4em .9em;
}

.key-value-container {
  &.font-bigger {
    .MuiTypography-caption {
      font-size: 1.5em;
    }

    .MuiTypography-h5 {
      font-size: 2em;
    }
  }
}

.link-secondary {
  position: relative;
  padding-right: 1.2em;

  a.link-secondary-icon {
    position: absolute;
    width: 1em;
    right: 0;
    top: 0;

    height: 100%;

    // color: inherit; // This can be used to remove the coloring
    text-decoration: none;

    .MuiSvgIcon-root {
      height: 100%; // Places it at the center, otherwise it's at the top
    }
  }
}

@import "utils";
@import "style/theme";
