@import '../../style/variables';

iframe.looker-preview-page {
  border: none;

  &.narrow {
    width: 67%;
    margin: 0 auto;
    height: 820px;
  }
}
