.risk-assessment-table {
  &.MuiPaper-root {
    padding: 1.5em;
  }

  .loading-row {
    .MuiTableCell-root {
      height: 20em;
    }
  }

  .MuiTable-root {
    .MuiTableCell-root {
      padding: 1em 1.5em;
    }

    .MuiTableRow-head {
      background-color: #193150;
      //background-color: #262626;

      .MuiTableCell-root {
        color: white;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    .risk-name-column {
      width: 17em;
    }

    .value-column {
      width: 9em;
    }

    .value-risk {
      font-weight: bold;
    }

    .risk-description-column {
      &.MuiTableCell-root {
        padding-right: 0;
      }

      .risk-description-cell-wrapper {
        display: flex;
        align-items: center;

        .risk-description-cell-text {
          flex-grow: 1;
        }

        .risk-description-cell-buttons {
          flex: 0 0 130px;

          .MuiButtonBase-root {
            margin-left: 15px;
          }
        }
      }
    }

    .reference {
      margin-top: 5px;
      line-height: 1;

      .MuiTypography-root {
        font-size: 0.75rem;
        line-height: 1;
        color: #888;
      }
    }

  }
}