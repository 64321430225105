@import 'src/style/colors';

.contract-opportunity-page {

  .chip-success {
    width: 100%;
    color: white;
    background-color: $colorGreen;
  }

  .chip-primary {
    width: 100%;
    color: white;
    background-color: $colorAccentBlue;
  }

  .chip-error {
    width: 100%;
    color: white;
    background-color: $colorRed;
  }

}