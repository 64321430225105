.manage-master-data-page {
  button {
    //text-transform: none;
  }

  .page-header {
    margin-top: 6em;
  }

  .page-content {
    margin-top: 0;
    margin-bottom: 0;
    min-height: 65vh;
  }

  .input-panel {
    .dataset-select {
      margin-top: 2em;
      margin-bottom: 0;
    }
  }

  .submit-panel {
    button {
      font-size: 1.2em;
      padding: .75em 1em;
      text-transform: none;

      .MuiSvgIcon-root {
        font-size: 2em;
      }
    }
  }
}

