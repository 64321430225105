@import '../../style/variables';
@import '../../style/colors';

.categorization-review-page {
}

.debug-btn.MuiButton-root {
  //background-color: #787800;
  background-color: $colorMainBlue;
  padding: .1em;
  margin: .1em;
  font-size: .6rem;
}
