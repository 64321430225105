@import 'src/style/variables';
@import 'src/style/colors';

$grayP: '44%';

.merge-x-cleanup-dashboard {
  margin-top: 1em;
  min-height: 53em;


  .icon path {
    fill: $colorDashboard;
  }

  .icon.stroke-adj path {
    stroke: $colorDashboard;
  }

  .MuiTypography-root {
    color: $colorDashboard;
    max-height: 1em;
  }

  //padding-top: 1em;
  //padding-bottom: 3em;
  .dashboard-row {
    margin-top: 2.5rem;

    .dashboard-row-title {
      margin-bottom: 0.5rem;

      .MuiTypography-root {
        font-size: 2rem;
        margin-bottom: 0;
        min-height: 1em;
      }
    }

    .dashboard-row-subtitle {
      margin-bottom: 6rem;

      .MuiTypography-root {
        font-size: 2.857rem;
        margin-bottom: 0;
        font-weight: bold;
        min-height: 1em;
      }
    }

    .dashboard-row-content {
    }

    .dashboard-card {
      display: block;
      position: relative;
      width: 100%;
      margin-bottom: 3.8em;

      &.btn-base {
        text-align: left;
        font-size: inherit;

        .MuiCard-root {
          padding-top: 1.357em;
          padding-bottom: 1.357em;

          border: solid;
          border-color: $colorDashboard;
          border-radius: 5px;
          border-width: 1px;
          //min-height: 8.7em;
        }
      }

      &.no-btn-base {
        padding-top: 1.357em;
        padding-bottom: 1.357em;

        border: solid;
        border-color: $colorDashboard;
        border-radius: 5px;
        border-width: 1px;
        //min-height: 8.2em;
      }


      .dashboard-card-container {
        min-height: 6em;

        .dashboard-card-container-r {
          //flex-basis: 75%;
        }
      }

      .MuiTypography-root {
        font-size: 20px;
        line-height: 1.2;
      }

      .card-title {
        margin-bottom: .85em;
      }

      &.two-line-title {
        .card-title {
          max-width: 14em;
          margin-bottom: 1.85em;
        }
      }

      &.only-title .card-title {
        margin-top: .5em;
      }

      .card-content {
        .value {
          font-weight: bold;

          &.green {
            color: #06A77D
          }

          &.red {
            color: #FF5757
          }

          &.gray {
            opacity: $grayP;
          }
        }

        .text {
          font-size: 15px;
          opacity: $grayP;
        }
      }

      .avatar {
        line-height: 0;
      }

      .dashboard-badge {
        position: absolute;
        right: 0;
        bottom: 0;

        width: 60px;
        height: 60px;
        background-image: linear-gradient(to bottom right, transparent 50%, $colorDashboard 0);
        border-bottom-right-radius: 5px;

        p {
          color: white;
          position: absolute;
          right: 0.45em;
          bottom: .25em;
          line-height: 1;
          text-align: right;
        }
      }

      &.btn-base.is-trial > .MuiCard-root,
      &.no-btn-base.is-trial {
        opacity: .6;
        background-color: #f2f2f2;
        cursor: no-drop;
      }
    }
  }
}

.dashboard-type-indicator {
  margin-right: .5em;

  p {
    font-size: 1rem;
    //font-weight: bold;
  }
}
