@import "src/style/colors";

.data-ingestion {

  .data-ingestion-page-content {
  }

  .data-ingestion-root-error,
  .data-upload-form,
  .gcp-bucket-upload-component,
  .direct-upload-component,
  .data-ingestion-page-action-button {
    margin-top: 2em;
  }

  &.data-overview-page {
    min-height: max(70vh, 50em);
  }

  .error-alert-container {
    margin-top: 4em;
  }

  .dataset-overview-table-titles {
    margin-top: 4em;
  }

  .dataset-overview-table-content {
    margin-top: 2em;
  }

  .dataset-overview-actions {
    margin-top: 4em;
    margin-bottom: 4em;
  }

  h2 {
    font-size: 1.5em;
    margin: 0.83em 0;
    font-weight: lighter;
    color: #193150;
  }

  h4 {
    color: #0C3352;
  }

  h6 {
    font-weight: lighter;
    color: #193150;
  }

  .data-ingestion-stepper {
    margin-top: 4em;
  }

  .upload-file-button {
    min-width: 10em;
  }

  .loading-spinner-pie {
    margin: 22em auto;
  }

  &.no-data {
    .dataset-overview-actions {
      margin-top: 22em;
      margin-bottom: 22em;
    }
  }

  .MuiChip-root {
    &.dataset-pending {
      color: white;
      background-color: $colorYellow;
      width: 80%;
    }

    &.dataset-uploaded {
      color: white;
      background-color: $colorGreen;
      width: 80%;
    }
  }
}

.dataset-modal {
  .filename {
    font-weight: bolder;
    word-break: break-all;
  }
}

