.line-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;

  .line-chart-title {
    text-align: center;
  }
}

svg.line-chart {
}
