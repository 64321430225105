svg.tree-map {
  border: 1px solid hsl(0, 0%, 86%);
  box-shadow: 0px 8px 16px hsla(0, 0%, 78%, 0.25);
  box-sizing: content-box;

  text {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }
}
