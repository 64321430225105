.contract-analytics-header {
  width: 100%;

  &.MuiCard-root {
    background-color: whitesmoke;
    border-radius: 0.5em;
    padding: 2em;
    margin: 2em 0 2em 0;
  }

  > .top-row {
    margin-bottom: 2em;
  }

  .avatar {
    line-height: 0;
  }

  .content .MuiTypography-root {
    font-weight: bold;
  }

  .content .MuiTypography-root {
    font-weight: inherit;
  }
}