@import "shadcn/lib/tailwind.scss";

body.app {
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/

    /*https://mui.com/material-ui/migration/v5-component-changes/#update-body-font-size*/
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
