@import 'src/style/colors';

.taxonomy-health-checker-page {


  scrollbar-width: thin;

  .taxonomy-health-checker-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: -4.5em 4em 1em 0em;
  }

  .taxonomy-health-checker-filters {
    margin-top: 3em;
    margin-bottom: 2em;
  }

  .taxonomy-health-checker-main-container {
    margin-top: 4em;
    margin-bottom: 4em;
  }


  .is-open {
    background-color: $colorGrayLight;
  }


  .taxonomy-health-check-table {

    .MuiTableCell-root {
      padding: 0.2em;
    }
  }

}

.rerun-button {
  margin: 20px;
}

.loading-mid-page-container {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  flex-direction: column;
  height: 50vh; /* Optional: Adjust height as needed */
  width: 100%;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 52vh;
  margin-top: 5vh;
}

.stepper-box {
  padding-top: 4vh;
}

.input-box {
  width: 40vh;
}

.run-btn {
  height: 4.5vh;
}

.top-margin {
  margin: auto;
  margin-top: 0vh;
}

.text-processing {
  font-size: 20px;
  margin-top: -4vh;
}