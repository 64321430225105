.edit-model-component {
  padding: 2em;
  min-width: 50em;
  min-height: 15em;
}

.mithra-modal-vertical-root .MuiPaper-root {
  padding: 2em;
}

.edit-model-component.approval-dialog {
  .MuiFormControl-root {
    margin-top: 2em;
  }

  min-width: auto;
  width: 50em;
}
