.part-recat-component {
  .MuiButtonBase-root {
    flex-wrap: wrap;
    justify-content: flex-start;

    .MuiChip-root {
      cursor: pointer;
    }
  }

  .MuiChip-labelSmall {
    line-height: 1.1em;
  }
}
