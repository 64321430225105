.share-modal {
  .small-header {
    color: #0C3353;
    font-weight: 400;
    margin-top: 1em;
  }

  .link-button {
    .MuiButton-startIcon {
      width: 20px;
    }
  }
}