.date-range-picker {
  position: relative;
  display: inline-block;
}

.date-range-button {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.5em;
  //width: 250px;
  width: 95%;

  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(25, 48, 79, 0.54);
}

.date-range-button p {
  margin: 0;
  font-size: 14px;
}

.date-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  box-shadow: 0px 0px 5px 0px #ccc;
  z-index: 10; //Added to not go under (i) icon of alert
}

.date-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  font-size: 14px; /* Adjust font size to make it smaller */
}
