.golden-record-modal {
  .MuiTextField-root {
    margin: 1em 0;
  }

  .MuiDialogActions-root {
    padding: 0 24px 20px;
  }

  .MuiButton-containedError {
    color: white;
  }
}