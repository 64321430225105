.manage-data-page {
  .title {
    margin-top: 2em;
    margin-bottom: .5em;
    padding-left: 2em;
  }

  .container-files {
    margin-top: 3em;
    margin-bottom: 5em; // To end of page
  }

  .dataset-select-component {
    min-height: 5.2em;

    .select-bag-field {
      min-width: 24em;
    }
  }

  section.buttons {
    text-align: center;
    margin-top: 3em;
  }
}

