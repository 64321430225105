.taxonomy-approval-chip-popper {
  &.wide {
    .MuiTooltip-tooltip {
      max-width: 50em;
    }
  }

  &.no-width-limit {
    .MuiTooltip-tooltip {
      max-width: none;
    }
  }

  .MuiChip-root {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 0.9);
    color: rgba(255, 255, 255, 0.9);
  }

  .MuiTypography-root.small-header {
    font-weight: bold;
    display: block;
  }
}
