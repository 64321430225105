@import '../../style/variables';
@import '../../style/colors';

.business-case-report-page {
  padding: 2em 1em;
  min-height: 40em;

  .header {

    .title.MuiTypography-root {
      font-size: 2.25rem;
      font-weight: 400;
      color: #091344;
      letter-spacing: 0.00938rem;

      min-height: 4rem; // To align with the header of the generator page
      line-height: 4rem;
    }

    .button-toolbar-right {
      .MuiButton-root {
        margin-left: 1em;
      }
    }
  }

  .success-factors-card {
    width: 100%;
    padding: 1em 2em;

    .MuiFormControl-root {
      margin: 1.5em 0;
    }
  }
}
