@import "src/style/variables";
@import "src/style/colors";

.top-other-visualization {
  width: 100%;
  margin-bottom: 3em;

  rect {
    fill: #193150;
  }

  text {
    text-anchor: middle;

    &.caption {
      fill: #193150;
      font-size: 16px;
    }

    &.label {
      fill: white;
      font-size: 30px;

      &.inverted {
        fill: #193150;
      }
    }
  }
}

.pareto-overview-visualization {
  color: #193150;
  min-height: 35em;

  .bar-chart-v2 {
    .highlight-line {
      stroke-width: 1px;
      stroke: #707070;
    }

    .highlight-text {
      fill: #707070;
    }

    g.bar-wrapper {
      &:hover {
        .hover-overlay {
          fill: none;
        }

        .bar {
          fill: #19315099;
        }

        .value-label {
          opacity: 1;
        }
      }

      &.uncat:hover .bar {
        fill: #de4b1e;
        opacity: .6;
      }

      &.rev .bar {
        fill: $colorGreen;
      }

      &.rev:hover .bar {
        fill: $colorGreenHighlight;
      }
    }
  }
}

.supplier-spend-visualization {
  .pareto-chart {
    .data-bars {
      .bar-wrapper {
        &:hover {
          .hover-overlay {
            fill: none;
          }

          .bar-parts.active {
            rect {
              fill: #19315099;
            }
          }
        }
      }
    }
  }
}

.supplier-breakdown-visualization {
  margin-top: 4em;
  min-height: 10em;

  .supplier-header {
    min-height: 1.5em;
    font-weight: lighter;
  }

  .parts-table {
    min-height: 59em;

    table {
      table-layout: fixed;
    }

    .col-p_name,
    .col-p_description,
    .col-p_context_1,
    .col-p_context_2,
    .col-p_context_3 {
      width: auto;
      min-width: 10em;

      &.col-type-date {
        width: 8em;
      }
    }

    .col-p_spend {
      width: 10em;
    }

    margin-bottom: $pageItemMargin;
  }
}

