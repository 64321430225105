.ppv-opportunity-status-dropdown {
  min-width: 280px;

  &.MuiFormControl-root {
    min-width: 18em;
  }

  .MuiInputBase-root {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    padding: 8px;

    .MuiSelect-select {
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
      // padding-right is set by the library
    }
  }
}

.ppv-opportunity-status-dropdown-menu, .ppv-opportunity-status-dropdown {
  .menu-wrapper {
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: calc(100% - 8px);

    .MuiListItemIcon-root {
      width: 24px;
      min-width: auto;
      margin-left: 12px;
    }

    .MuiListItemText-root {
      text-align: center;
      margin-right: 12px;
    }
  }
}
