.donut-chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

svg.donut-chart {
  //border: 1px solid black;
}

.donut-chart-container > .tooltip {
  position: absolute;
  background-color: white;
  //border: 1px solid black;
  padding: 1em;
  border-radius: 3px;
}
