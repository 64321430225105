@import 'src/style/colors';

.taxonomy-health-checker-page {


  scrollbar-width: thin;

  .taxonomy-health-checker-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2em 2em 1em 2em;
  }

  .taxonomy-health-checker-filters {
    margin-top: 3em;
    margin-bottom: 2em;
  }

  .taxonomy-health-checker-main-container {
    margin-top: 4em;
    margin-bottom: 4em;
  }

  .MuiTableCell-root {
    //background-color: purple;
    //max-width;
  }

  .is-open {
    background-color: $colorGrayLight;
  }


  .taxonomy-health-check-table {

    .MuiTableCell-root {
      padding: 0.2em;
    }
  }

}

.rerun-button {
  margin: 20px;
}

.loading-mid-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Optional: Adjust height as needed */
}