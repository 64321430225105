@import "src/style/colors";

.taxonomy-approval-table {
  // FOR ALL
  .MuiTableCell-root {
    padding: 2px 0.5em;

    &.navigation:not(.clickable) {
      cursor: default;
    }

    &.narrow-button {
      .MuiIconButton-root {
        padding: 0;
      }
    }

    &.open-close {
      width: 20px;

      .MuiIconButton-root {
        padding: 0;
      }
    }

    & > .MuiTextField-root {
      .MuiInput-underline:before {
        border-bottom-color: rgba(0, 0, 0, 0.0);
      }
    }

    &.notes-column {
      width: 20em;
      max-width: 30em;

      .MuiTypography-root, textarea {
        font-size: 0.875rem;
      }
    }

    &.edit-actions {
      width: 170px;
      min-width: 170px;
      padding: 0 3px;

      .MuiButtonBase-root {
        padding: 5px;
        margin: 0 3px;
      }
    }

  }

  // FOR THE REVIEW TABLE
  &.taxonomy-approval-preview {
    .MuiTableCell-root {
    }
  }

  // FOR THE APPROVAL TABLE
  &.taxonomy-approval-review {
    .MuiTableCell-root {
    }
  }

  .add-l1-row {
    .MuiTableCell-root {
      border-bottom: none;
      &.edit-actions {
        .MuiButtonBase-root {
          color: rgba(0, 0, 0, 0.54);
          padding: 5px 10px;
        }
      }
    }
  }


  .MuiChip-colorWarning {
    border-color: $colorYellowOnWhite;
    color: $colorYellowOnWhite;
  }

  .MuiChip-deleteIcon.MuiChip-deleteIconColorWarning {
    color: $colorYellowOnWhite;
  }

  .MuiChip-colorSuccess {
    border-color: $colorGreen;
    color: $colorGreen;
  }

  .MuiChip-deleteIcon.MuiChip-deleteIconColorSuccess {
    color: $colorGreen;

    &:hover {
      color: $colorGreenHighlight;
    }
  }

  .MuiChip-colorSecondary {
    border-color: $colorAccentBlue;
    color: $colorAccentBlue;
  }

  .MuiChip-deleteIcon.MuiChip-deleteIconColorSecondary {
    color: $colorAccentBlue;
  }
}
