@import 'src/style/colors';

.taxonomy-suggestor-tableV2 {

  .accept-reject-button {
    color: $colorGray;
  }
}

.taxonomy-suggestor-row {}

/* Default styles for IconButton */
.accept-reject-button svg {
  color: $colorGray;
}

/* Styles for accepted IconButton */
.accept-reject-button.accepted svg {
  color: $colorGreen;
}

/* Styles for rejected IconButton */
.accept-reject-button.rejected svg {
  color: $colorRed;
}

.warning-icon {
  color: $colorYellow;
}

.chip {
  &.Deleted {
    background-color: $colorRed;
    color: white;
  }

  &.Added {
    background-color: $colorAccentBlue;
    color: white;
  }

  &.Renamed {
    background-color: $colorYellow;
    color: white;
  }

  &.LabelFormat {
    background-color: $colorYellow;
    color: white;
  }

  &.PrefixOverlap {
    background-color: $colorAccentBlue;
    color: white;
  }

  &.CategoryOverlap {
    background-color: $colorRed;
    color: white;
  }

  &.Healthy {
    background-color: $colorGreen;
    color: white;
  }

}

.taxonomy-suggestor-page {
  .is-open {
    background-color: $colorGrayLight;
  }
}

.suggestion-table-v2-component th {
  opacity: 1;
  z-index: 10000;
}