@import "../../../../style/variables";
@import "../../../../style/colors";

.accept-buttons {
  .accept {
    cursor: pointer;
    transition: color 0.3s;
    color: $colorPrimaryBlue;

    &.active {
      color: $colorGreen;
    }

    // For debug
    //&.inactive {
    //  //color: red;
    //}
    //&.disabled {
    //  cursor: not-allowed;
    //  opacity: 0.5; // Set the opacity for the disabled state
    //}
  }

  .MuiButtonBase-root {
  }

  .MuiSvgIcon-root {
  }
}
