.synergy-categories-page {

  .page-bag-titles {
    margin-bottom: 64px;

    .bag-title {
      text-align: center;
      font-weight: lighter;
    }
  }

  .page-breakdown-viz {
    margin-bottom: 64px;

    .breakdown-values {
      text-align: center;

      .title {
        font-weight: lighter;
        margin-bottom: .3em;
        line-height: 1;
      }

      .value {
        margin-bottom: 1.4em;
        line-height: 1;
      }
    }

    @media (max-width: 1280px) {
      .breakdown-values {
        .title {
          font-size: 1.5rem;
          margin-bottom: .5em;
        }

        .value {
          font-size: 2rem;
          margin: 0;
        }

        .top-breakdown {
          margin-top: 1.5em;
        }

        .bottom-breakdown {
          margin-bottom: 4.5em;
        }
      }
    }
  }

  .synergy-categories-cmp-table {
    $colWidthDropdown: 75px;
    $colWidthDropdownTablet: 25px;
    $colWidthName: auto;
    $colWidthSpend: 150px;
    $colWidthSpendTablet: 120px;

    .MuiTableCell-root {
      line-height: 1;
      padding-left: 0;
      padding-right: 0;

      &.col-dropdown {
        width: $colWidthDropdown;
      }

      &.col-s_name {
        width: $colWidthName;
      }

      &.col-s_spend {
        width: $colWidthSpend;
      }

      //&.col-opportunity {
      //  width: 25px;
      //}

      &.cell-sub-table {
        padding: 0 0 0 $colWidthDropdown;
      }
    }

    @media (max-width: 1280px) {
      .MuiTableCell-root {
        &.col-dropdown {
          width: $colWidthDropdownTablet;
        }

        &.col-s_spend {
          width: $colWidthSpendTablet;
        }

        &.cell-sub-table {
          padding: 0 0 0 $colWidthDropdownTablet;
        }
      }
    }

    .sub-table {
      .MuiTableCell-head {
        line-height: 2em;
      }

      .MuiTableCell-root {
        &.col-p_name {
          width: $colWidthName;
        }

        &.col-p_spend {
          width: $colWidthSpend;
        }

        //&.col-opportunity {
        //  width: 25px;
        //}
      }
    }
  }

  .match-categories-toolbar {
    margin-top: 1em;
    margin-bottom: 2em;

    button {
      margin: 0 1em;
    }
  }

  .match-categories-viz {
    min-height: 45em;
  }
}

div.category-synergy-modal {
  //width: 900px;

  .value-title {
    font-weight: bold;
  }
}
